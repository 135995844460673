const ajaxDebug = (a) => {

  const data = {
    action: 'ajax_debug',
    content: a,
  };

  $.post( '/wp-admin/admin-ajax.php', data);
}

export { ajaxDebug };
