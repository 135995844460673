import { getCookie, setCookie } from './components/cookie';
import { ajaxDebug } from './components/ajax-debug-log';
// import qs from 'qs';
import 'jquery-validation/dist/jquery.validate';

// import axios from 'axios/dist/axios';
import md5 from 'blueimp-md5/js/md5';

jQuery(document).ready(($) => {

  let formSent = false;

  const activateSelectedInputs = () => {
    $('.js-signup-wrap .input-group--2 input').each(function () {
      const that = $(this);

      if (that.val() != '') {
        that.siblings('label').addClass('is-active');
      }
    });
  }

  activateSelectedInputs();


  let fbEventId = '';
  //user's role
  $(document).on('click', '.js-feat-box', e => {
    activateSelectedInputs();

    const that = $(e.currentTarget);
    const role = that.attr('data-role');
    $('.content-section-1--step-1').addClass('hidden');
    $('.content-section-1--step-2').removeClass('hidden');
    $('.content-section-3--step-1').addClass('hidden');
    $('.content-section-3--step-2').removeClass('hidden');

    $('.js-role-switcher').removeClass('is-active');
    $(`.js-role-switcher[data-role="${role}"]`).addClass('is-active');

    $('.js-signup input[name="role"]').val(role);

    if (role == 'investor') {
      $('.js-signup-wrap').addClass('hidden');
      $('.js-investor-calendar').removeClass('hidden');
    } else {
      $('.js-signup-wrap').removeClass('hidden');
      $('.js-investor-calendar').addClass('hidden');
    }

    if (role == 'veterinarian') {
      $('.js-signup-wrap .js-practice-name').removeClass('hidden');
      $('.js-signup-wrap .js-employee-role').addClass('hidden').removeClass('flex');
      $('.js-signup-wrap .js-phone-group').removeClass('hidden');
      $('.js-signup-wrap .checkbox-group--text-messages').removeClass('hidden');
    } else {
      $('.js-signup-wrap .js-practice-name').addClass('hidden');
      $('.js-signup-wrap .js-employee-role').removeClass('hidden').addClass('flex');
      $('.js-signup-wrap .js-phone-group').addClass('hidden');
      $('.js-signup-wrap .checkbox-group--text-messages').addClass('hidden');
    }

    if ($(window).width() < 992) {
      const scrollTop = $('.content-section-1--step-2').length ? $('.content-section-1--step-2').offset().top - 50 : $('.content-section-3--step-2').offset().top - 50;
      $('html, body').animate({
        scrollTop: scrollTop,
      }, 0);
    }
  });

  $(document).on('click', '.js-role-switcher', e => {
    activateSelectedInputs();

    const that = $(e.currentTarget);
    const role = that.attr('data-role');

    $('.js-role-switcher').removeClass('is-active');
    that.addClass('is-active');

    $('.js-signup input[name="role"]').val(role);

    if ( role == 'investor' ) {
      $('.js-signup-wrap').addClass('hidden');
      $('.js-investor-calendar').removeClass('hidden');
    } else {
      $('.js-signup-wrap').removeClass('hidden');
      $('.js-investor-calendar').addClass('hidden');
    }


    if ( role == 'veterinarian' ) {
      $('.js-signup-wrap .js-practice-name').removeClass('hidden');
      $('.js-signup-wrap .js-employee-role').addClass('hidden').removeClass('flex');
      $('.js-signup-wrap .js-phone-group').removeClass('hidden');
      $('.js-signup-wrap .checkbox-group--text-messages').removeClass('hidden');
    } else {
      $('.js-signup-wrap .js-practice-name').addClass('hidden');
      $('.js-signup-wrap .js-employee-role').removeClass('hidden').addClass('flex');
      $('.js-signup-wrap .js-phone-group').addClass('hidden');
      $('.js-signup-wrap .checkbox-group--text-messages').addClass('hidden');
    }

    if ( $(window).width() < 992 ) {
      const scrollTop = $('.content-section-1--step-2').length ? $('.content-section-1--step-2').offset().top - 50 : $('.content-section-3--step-2').offset().top - 50;
      $('html, body').animate({
        scrollTop: scrollTop,
      }, 0);
    }
  });

  //back to prev step
  $(document).on('click', '.js-form-back', () => {
    $('.content-section-1--step-1').removeClass('hidden');
    $('.content-section-1--step-2').addClass('hidden');
    $('.content-section-3--step-1').removeClass('hidden');
    $('.content-section-3--step-2').addClass('hidden');
  });

  //validation
  if ( $('.js-signup').length ) {
    $('.js-signup').each(function() {
      $(this).validate({
        f_name: {
          required: true,
        },
        l_name: {
          required: true,
        },
        city: {
          required: true,
        },
        phone: {
          required: true,
        },
        acceptance: {
          required: true,
        },
        email: {
          required: true,
          email: true,
          regex: /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i,
        },
      });
    });
  }

  //create WP user & submit data to HS
  $('.js-signup').on('submit', (e) => {
    const that = $(e.currentTarget);
    const formValidation = that.valid();

    if ( formValidation != true ) {
      return;
    }

    that.find('.error-box').addClass('hidden');
    that.find('button').addClass('is-loading').attr('disabled', 'true');
    let regLink = '';
    let country = '';
    let regionXhr = '';
    let zip = '';
    const hutkCookie = getCookie('hubspotutk');
    const clarityUserId = getCookie('_clck') ? getCookie('_clck') : '';
    const f_name = that.find('input[name="f_name"]').val();
    const l_name = that.find('input[name="l_name"]').val();
    const email = that.find('input[name="email"]').val();
    const role = that.find('input[name="role"]').val();
    let acceptance = '';
    let acceptance2 = '';
    const phone_number = that.find('input[name="phone"]').val() ? that.find('input[name="phone"]').val() : '';
    const city = that.find('input[name="city"]').val() ? that.find('input[name="city"]').val() : '';
    const region = that.find('input[name="region"]').val() ? that.find('input[name="region"]').val() : '';
    const ref = that.find('input[name="ref"]').val() ? that.find('input[name="ref"]').val() : '';
    const eRole = that.find('select[name="e_role"]').val() ? that.find('select[name="e_role"]').val() : '';
    const experience = that.find('select[name="experience"]').val() ? that.find('select[name="experience"]').val() : '';

    //popup fields
    const revenue = that.hasClass('js-popup-signup-steps') ? that.find('#revenue').val() : '';
    const ebitda = that.hasClass('js-popup-signup-steps') ? that.find('#ebitda').val() : '';
    const fTimeDoctors = that.hasClass('js-popup-signup-steps') ? that.find('#f-time-doctors').val() : '';
    const examRooms = that.hasClass('js-popup-signup-steps') ? that.find('#exam-rooms').val() : '';
    const multipleToday = that.hasClass('js-popup-signup-steps') ? that.find('#multiple-today').val() : '';

    let hsForm = true;

    if ( $('.js-popup-signup-steps').length ) {
      console.log('setCookie');
      setCookie('revenue', revenue, 365);
      setCookie('ebitda', ebitda, 365);
      setCookie('doctors', fTimeDoctors, 365);
      setCookie('rooms', examRooms, 365);
      setCookie('multiple', multipleToday, 365);
    }

    if ( that.find('input[name="form_acceptance"]').is(':checked') ) {
      acceptance = 'Yes';
    } else {
      acceptance = 'No';
    }

    if ( that.find('input[name="accept_text_messages"]').is(':checked') ) {
      acceptance2 = 'Yes';
    } else {
      acceptance2 = 'No';
    }

    // formSent = true;

    const fieldsArray = [
      {
        "name": "firstname",
        "value": f_name,
      },
      {
        "name": "lastname",
        "value": l_name,
      },
      {
        "name": "email",
        "value": email,
      },
      {
        "name": "phone",
        "value": phone_number,
      },
      {
        "name": "city",
        "value": city,
      },
      {
        "name": "state",
        "value": region,
      },
      {
        "name": "clarity_user_id",
        "value": clarityUserId,
      },
      {
        "name": "role",
        "value": role,
      },
      {
        "name": "years_of_experience",
        "value": experience,
      },
      {
        "name": "select_your_role",
        "value": eRole,
      },
      {
        "name": "experiment___unique_link",
        "value": regLink,
      },
      {
        "name": "experiment___referral_link",
        "value": ref,
      },
      {
        "name": "form_acceptance",
        "value": acceptance,
      },
      {
        "name": "accept_text_messages",
        "value": acceptance2,
      },
      {
        "name": "user_referral_link",
        "value": `https://galaxyvets.com/?ref=${md5(email)}`,
      },
    ];

    let contextObj = {
      "pageUri": window.location.href,
      "pageName": $('head title').text(),
      "ipAddress": window.userIP ? window.userIP : undefined,
    }

    if ( hutkCookie ) {
      contextObj.hutk = hutkCookie;
    }

    $.when( $.ajax({
        url: role == 'veterinarian' ? 'https://api.hsforms.com/submissions/v3/integration/submit/20247445/c82fa17c-64ff-4cd2-99a1-d6f0cc3615a1' : 'https://api.hsforms.com/submissions/v3/integration/submit/20247445/899f4ffd-2cf8-4d27-9134-9911d324e59e',
        method: 'post',
        contentType: 'application/json',
        // dataType: 'JSON',
        data: JSON.stringify({
          "submittedAt": Date.now(),
          "fields": fieldsArray,
          "context": contextObj,
        }),
      })
      .catch(
        e => {
          console.log(e);
          that.find('button').removeClass('is-loading').removeAttr('disabled');
          if ( e.status != 400 ) {
            that.find('.error-box').removeClass('hidden');
            that.find('.error-box p').text(e.responseJSON.message);
          }

          if ( e.responseJSON.errors[0].message == "Error in 'fields.email'. Invalid email address" ) {
            that.find('.error-box').removeClass('hidden');
            that.find('.error-box p').text('We tried to send you an email, and it bounced back. Can you please enter a new email address?');
          }

          const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
          ajaxDebug(errorMsg);
          console.log(errorMsg);

          if ( e.status == 400 ) {
            hsForm = false;
          }
        }
      )

      .then(
        () => {

          //

          if ( hsForm == true ) {
            that.find('input').val('');
            that.find('input[type="checkbox]').prop('checked', false);

            //ajax request /wp-json/marketing/v1/conversion-api with email, role, fb_event_id
            $.ajax({
              url: '/wp-json/marketing/v1/conversion-api',
              method: 'post',
              data: {
                email: email,
                role: role,
                fb_event_id: fbEventId,
                f_name: f_name,
                l_name: l_name,
                phone_number: phone_number,
                page_url: window.location.href,
                fordm_id: role == 'veterinarian' ? '899f4ffd-2cf8-4d27-9134-9911d324e59e' : 'c82fa17c-64ff-4cd2-99a1-d6f0cc3615a1',
                city: city,
              },
              complete: () => {
                that.find('button').removeClass('is-loading').removeAttr('disabled');

                setTimeout(() => {
                  if ($('.js-signup-redirect').length) {
                    window.location = $('.js-signup-redirect').attr('data-url');
                  } else {
                    $('.content-section-3--step-3').removeClass('hidden');
                    $('.content-section-3--step-2').addClass('hidden');
                  }
                }, 100);
              },
              success: (res) => {
                console.log(res);
                fbEventId = res.fb_event_id;
                country = res.country;
                zip = res.zip;
                regionXhr = res.region;

                if (window.dataLayer) {
                  window.dataLayer.push({
                    'event': 'formSubmission',
                    'form_id': role == 'veterinarian' ? '899f4ffd-2cf8-4d27-9134-9911d324e59e' : 'c82fa17c-64ff-4cd2-99a1-d6f0cc3615a1',
                    'role': role,
                    'email': email,
                    'first_name': f_name,
                    'last_name': l_name,
                    'fb_event_id': fbEventId,
                    'phone_number': phone_number,
                    'country': country,
                    'zip': zip,
                    'city': city,
                    'region': regionXhr,
                    'status': 'registered',
                    'ip': window.userIP,
                    'user_agent': navigator.userAgent,
                  });
                }
              },
              error: (e) => {
                console.log(e);
              },
            });
          }
        }
      )
    );

    return false;
  });
});
